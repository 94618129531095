import { ContentPasteOffSharp } from '@mui/icons-material';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import app from '../firebase'

const db = app.firestore();

const getUserById = async(id) => {
    const userQuery = db.collection("users").where("id", "==", id);
    const userList = await userQuery.get()
    const user = userList.docs[0].data()
    return ({
        name: user.name,
        email: user.email,
        role: user.role
    });
}

const getAllUsers = async() => {
    const usersCollecton = await db.collection("users").get();
    const users = usersCollecton.docs.map(doc => {return doc.data()});
    return users;
}

const addDocument = async(data) => {
    const storageRef = await app.storage().ref()
    const fileRef = await storageRef.child(data.file.name);
    await fileRef.put(data.file);
    const fileUrl = await fileRef.getDownloadURL()

    //Add data to firestore
    const document = await app.firestore().collection("documents").add({
        name: data.file.name,
        category: data.category,
        url: fileUrl,
    });

    return document;
}

const getDocumentsByCategory = async(categoryId) => {
    const documentQuery = db.collection("documents").where("category", "==", categoryId.toString())
    const documentsRef = await documentQuery.get()
    return documentsRef.docs.map(doc => {return doc.data()});
}

const getAllDocuments = async() => {
    const documentCollecton = await db.collection("documents").get();
    const documents = documentCollecton.docs.map(doc => {return doc.data()});
    return documents;
}

const addSite = async(data) => {
    const site = {
        name: data.name,
        description: data.description,
        longitude: data.longitude ? data.longitude : '',
        latitude: data.latitude ? data.latitude : '',
        isActive: data.isActive ? data.isActive : false,
    }
    const result = await app.firestore().collection("sites").add(site);
    return result;
}

const getAllSites = async() => {
    const siteCollection = await db.collection("sites").get();
    const sites = siteCollection.docs.map(doc => {
        const data = doc.data();
        data.id = doc.id;
        return {
            id: doc.id,
            name: data.name,
            description: data.description,
            longitude: data.longitude,
            latitude: data.latitude,
            isActive: data.isActive,
        };
    });
    return sites;
}


const addEvent = async(data, currentUser) => {
    const newEvent = {
        title: data.title,
        start: data.start,
        end: data.end,
        comment: data.comment ? data.comment : '',
        isDone: data.isDone,
        note: data.note,
    }
    const result = await app.firestore().collection("events").add(newEvent);
    addEventLog({
        id: result.id,
        user: currentUser.email,
        activity: "Created",
        comment: data.comment ? data.comment : '',
    })
    return result;
}

const deleteEvent = async(id) => {
    const documentQuery = db.collection("events").doc(id);
    const result = await documentQuery.delete()
    return result;
}

const updateEvent = async(id, data, currentUser) => {
    const documentQuery = db.collection("events").doc(id);
    const result = await documentQuery.update({
        title: data.location,
        start: data.start,
        end: data.end,
        note: data.note,
        isDone: data.isDone,
    })

    addEventLog({
        id: id,
        user: currentUser.email,
        activity: "Updated",
        comment: data.comment ? data.comment : '',
    })
    return result
}

const getAllEvents= async() => {
    const eventCollecton = await db.collection("events").get();
    const events = eventCollecton.docs.map(doc => {
        const data = doc.data();
        data.id = doc.id;
        return {
            id: doc.id,
            title: data.title,
            start: data.start.toDate(),
            end: data.end.toDate(),
            note: data.note,
            isDone: data.isDone,
        };
    });
    return events
}

const addEventLog = async(data) => {
    const eventLog = {
        id: data.id,
        actionBy: data.user,
        activity: data.activity,
        comment: data.comment,
        date: new Date(),
    }
    const result = await app.firestore().collection("eventLogs").add(eventLog);
    return result;
}

const getEventLogsById = async(id) => {
    const Query = db.collection("eventLogs").where("id", "==", id)
    const logRef = await Query.get()
    return logRef.docs.map(doc => {
        const data = doc.data()
        return {
            actionBy: data.actionBy,
            activity: data.activity,
            comment: data.comment,
            date: data.date.toDate(),
        }
    });
}

const addLogs = async(data) => {
    const result = await app.firestore().collection("logs").add(data);
    return result;
}

export {
    getUserById,
    getAllUsers,
    addDocument,
    getAllDocuments,
    getDocumentsByCategory,
    addEvent,
    getAllEvents,
    addLogs,
    deleteEvent,
    updateEvent,
    addSite,
    getAllSites,
    addEventLog,
    getEventLogsById,
};
