import React, { useRef, useState } from 'react'
import {Form, Button, Card, Alert } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { Link } from 'react-router-dom'
import LogoContainer from './LogoContainer'

 const ForgotPassword = () =>  {
    const emailRef = useRef();
    const { resetPassword } = useAuth();
    const [error, setError ] = useState("")
    const [message, setMessage] = useState("")
    const [ loading, setLoading ] = useState(false);

    const handleSubmit = async(e) => {
        e.preventDefault();

        try {
            setMessage('')
            setError("");
            setLoading(true)
            await resetPassword(emailRef.current.value)
            setMessage('Check your inbox for further instruction')
        } catch (error) {
            setError(error.message.replace('Firebase: ',''))
        }
        setLoading(false)
        
    }

    return (
        <>
          <Card style={{maxWidth: "400px"}} className=" mt-4 col-12 col-sm-10 col-md-8 col-lg-4 col-xl-4 mx-auto">
            <Card.Body>
                <div className="d-block mx-auto mb-5"><LogoContainer /></div>
                {error && <Alert variant="danger">{error}</Alert> }
                {message && <Alert variant="success">{message}</Alert> }
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" id="email">
                        <Form.Label>User Email</Form.Label>
                        <Form.Control type="email" ref={emailRef} required />
                    </Form.Group>
                    <Button disabled={loading} className="btn-custome-primary w-100 mt-2" type="submit">Reset Password</Button>
                </Form>
                <div className="w-50 text-center mt-3 mx-auto">
                    <Link to="/login">Login</Link>
                </div>
            </Card.Body>
          </Card>  
        </>
    )
}

export default ForgotPassword;