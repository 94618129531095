import React, { useState, useEffect } from 'react'
import {Form, Button, Card, Alert, Container, ListGroup, Row } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { getUserById } from '../contexts/DbContext'

export default function Profile() {
    const [ error, setError ] = useState("")
    const [ name, setName ] = useState("")
    const [ role, setRole ] = useState("")
    const [ loading, setLoading ] = useState(false);
    const { currentUser, logout } = useAuth();

    useEffect(async () => {
        setLoading(true);
        try {
            const userProfile = await getUserById(currentUser.uid);
            setName(userProfile.name);
            setRole(userProfile.role)
        } catch (error) {
            setError(error)
        }
        
        setLoading(false)
    }, [])

    return (
        <>
            <Container>
                <Row>
                    {!loading && <Card className="col-12 col-xs-12 col-ms-8 col-md-8 mt-2 m-auto">
                        <Card.Body>
                        {error && <Alert variant="danger">{error}</Alert> }
                        <Card className="">
                                <Card.Body style={{backgroundColor:"#3289a8"}}>
                                    <h4 className="text-center text-white">Profile</h4>
                                </Card.Body>
                            </Card>  
                        <ListGroup variant="flush">
                            <ListGroup.Item><b>Name:</b> {name}</ListGroup.Item>
                            <ListGroup.Item><b>Email:</b> {currentUser.email}</ListGroup.Item>
                            <ListGroup.Item><b>Role:</b> {role}</ListGroup.Item>
                        </ListGroup>
                        </Card.Body>
                    </Card>}
                </Row>
            </Container>
        </>
    )
}
