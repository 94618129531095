import React, {useEffect, useState, useRef, setState} from 'react'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import 'reactjs-popup/dist/index.css';
import {Form, Button, Card, Alert, 
        Container, Row, Col, Accordion, Table} from 'react-bootstrap'
import Popup from 'reactjs-popup'
import moment from  'moment'
import DatePicker from 'react-date-picker'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getAllEvents, addEvent, 
        deleteEvent, 
        updateEvent, 
        getAllSites,
        getEventLogsById
    } from '../contexts/DbContext'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from '../contexts/AuthContext'
import DataTable from 'react-data-table-component';

const columns = [
    {
        name: 'AcationBy',
        selector: row => row.actionBy,
    },
    {
        name: 'Activity',
        selector: row => row.activity,
    },
    {
        name: 'Comment',
        selector: row => row.comment,
    },

];

const MyCalendar = () => {
    const [events, setEvents] = useState([]);
    const [open, setOpen] = useState(false);
    const [openCreateEvent, setOpenCreateEvent] = useState(false);
    const closeModal = () => setOpen(false);
    const [error, setError] = useState(false)
    const closeCreateEventModal = () => setOpenCreateEvent(false)
    const [datePick, setDatePick] = useState(new Date());
    const [eventDetail, setEventDetail] = useState({})
    const newTitleRef = useRef()
    const noteRef = useRef()
    const [ loading, setLoading ] = useState(false);
    const localizer = momentLocalizer(moment)
    const { currentUser } = useAuth();

    //Event edit
    const [ isEdit, setIsEdit ] = useState(false)
    const [ editLocationRef, setEditLocationRef ] = useState()
    const [ editNoteRef, setEditNoteRef ]= useState()
    const [ editCommentRef, setEditCommentRef ]= useState()
    const [ editDatePickRef, setEditDatePickRef ] = useState(new Date())
    const [ eventLogs, setEventLogs ] = useState([])

    //Sites
    const [ sites, setSites ] =useState([]);



    let allViews = [Views.MONTH, Views.WEEK, Views.DAY]

    const handlerOnSelectEvent = async(e) => {
        setIsEdit(false)
        setEventDetail({
            id: e.id,
            title: e.title,
            start: moment(e.start).format('DD/MM/YYYY'),
            end: moment(e.end).format('DD/MM/YYYY'),
            note: e.note,
            isDone: e.isDone,
        })
        setEditDatePickRef(e.start)
        setEditLocationRef(e.title)
        setEditNoteRef(e.note)
        setEditCommentRef('')
        setOpen(true)
        setEventLogs(await getEventLogsById(e.id))
       
    }

    const handleCreateEventOpen = (e) => {
        setOpenCreateEvent(true)
    }

    const handleCreateEventSubmit = async(e) => {
        e.preventDefault();
        const data = {
            title: newTitleRef.current.value,
            start: new Date(datePick),
            end: new Date(datePick),
            allDay: true,
            isDone: false,
            note: noteRef.current.value
        }
        try {
            setError("");
            setLoading(true)
            const result = await addEvent(data, currentUser)
        } catch (error) {
            setError(error.message)
        }
        setEvents(await getAllEvents())
        setLoading(false)
        closeCreateEventModal();

    }

    const handleDeleteEvent = async(e) => {
        e.preventDefault();
        try {
            setError("");
            setLoading(true)
            await deleteEvent(eventDetail.id)
            setEvents(await getAllEvents())
        } catch (error) {
            setError(error.message)
        }
        
        setLoading(false)
        closeModal();

    }

    //Hanlde edit event
    const handleEditEventSave = async(e) => {
        e.preventDefault();
        try {
            setError("");
            setLoading(true)
            const data = {
                location: editLocationRef,
                start: new Date(editDatePickRef),
                end: new Date(editDatePickRef),
                note: editNoteRef,
                isDone: eventDetail.isDone,
                comment: editCommentRef,
            }
            await updateEvent(eventDetail.id, data, currentUser)
            setEvents(await getAllEvents())
            
        } catch (error) {
            setError(error.message)
        }

        setLoading(false)
        closeModal();
    }

    const handleCollectedEvent = async(e) => {
        e.preventDefault();
        try {
            setError("");
            setLoading(true)
            const data = {
                location: editLocationRef,
                start: new Date(editDatePickRef),
                end: new Date(editDatePickRef),
                note: editNoteRef,
                isDone: true
            }
            await updateEvent(eventDetail.id, data, currentUser)
            setEvents(await getAllEvents())
            
        } catch (error) {
            setError(error.message)
        }

        setLoading(false)
        closeModal();
    }

    const handleEditEvent = (e) => {
        e.preventDefault();
        setIsEdit(true)
    }

    const ColoredDateCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
        style: {
            backgroundColor: 'lightblue',
        },
    })

    const eventStyleGetter = (event, start, end, isSelected) => {
        var backgroundColor = '#' + event.hexColor;
        if(event.isDone){
            var backgroundColor = '#32a86f';
        }

        var style = {
            backgroundColor: backgroundColor,
        };
        return {
            style: style
        };
    }

    useEffect(async() => {
        setSites(await getAllSites())
        setEvents(await getAllEvents())
    }, [])
    return(
    <>
    <Container fluid className="mt-1">
    {error && <Alert variant="danger">{error}</Alert> }
        <Row className="mb-1">
            <Col className="d-flex justify-content-end">
                <Button className="btn btn-custome-primary" onClick={handleCreateEventOpen}>
                    Create Event
                </Button>
            </Col>
        </Row>
        <Row>
            <Calendar
                localizer={localizer}
                events={events}
                views={allViews}
                drilldownView="agenda"
                selectable={true}
                showMultiDayTimes
                onSelectEvent={handlerOnSelectEvent}
                style={{ height: 600 }}
                popup={true}
                components={{
                    timeSlotWrapper: ColoredDateCellWrapper,
                }}
                eventPropGetter={eventStyleGetter}
            />
      </Row>
      <Row>
        <Popup open={open} onClose={closeModal } 
            className=" mt-2 col-12 col-sm-10 col-md-8 col-lg-4 col-xl-4 mx-auto">
            <Card >
                <Card.Body>
                    <Row>
                        <Col className="d-flex justify-content-end">
                            <Button className="btn-close btn-outline-dark" onClick={closeModal} aria-label="Close" />
                        </Col>
                    </Row>

                    <Card.Title>Sampling Details</Card.Title>
                    <Row>
                        <Col className="d-flex justify-content-end">
                            <Card.Text>Status : <b>{eventDetail.isDone ? "Collected" : "Pending"}</b></Card.Text>
                        </Col>
                    </Row>
                    
                    <Form onSubmit={handleEditEventSave}>
                        <Form.Group className="mb-3" id="name">
                            <Form.Label>Site</Form.Label>
                            <Form.Control as="select" defaultValue="Chose..." value={editLocationRef} 
                                onChange={(e) => setEditLocationRef(e.target.value)} required disabled={!isEdit}>
                                {sites.map((site) => <option value={site.name}>{site.name}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3" id="role">
                            <Form.Label>Date</Form.Label>
                            <div>
                                <DatePicker 
                                    onChange={setEditDatePickRef}
                                    value={editDatePickRef}
                                    required={true}
                                    disabled={!isEdit}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Note</Form.Label>
                            <Form.Control value={editNoteRef} 
                                onChange={(e) => setEditNoteRef(e.target.value)} as="textarea" 
                                rows={2} id="note" readOnly={!isEdit} />
                        </Form.Group>
                        {isEdit && 
                        
                            <Row className="mt-2">                        
                            <Form.Group>
                                <Form.Label>Comment</Form.Label>
                                <Form.Control value={editCommentRef} 
                                    onChange={(e) => setEditCommentRef(e.target.value)} as="textarea" 
                                    rows={2} id="note" readOnly={!isEdit}  required={isEdit}/>
                            </Form.Group>
                                <Button disabled={loading} className="btn-custome-primary w-100 mt-2" type="submit">Save</Button>
                            </Row>
                        }
                        <Row className="mt-2 d-none d-xs-none d-sm-none d-md-block">
                            <Col>
                                <Accordion defaultActiveKey="0" >
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header >Action Logs</Accordion.Header>
                                        <Accordion.Body>
                                            <DataTable
                                                columns={columns}
                                                data={eventLogs}
                                                progressPending={loading}
                                                fixedHeader
                                                fixedHeaderScrollHeight = {'200px'}
                                            />
                                       </Accordion.Body>         
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>
                    </Form>
                    

                    {(!isEdit && !eventDetail.isDone) && 
                        <Row className="mt-2">
                            <Col className="d-flex">
                                <Button  disabled={loading} className="btn btn-custome-primary" onClick={handleCollectedEvent}>
                                    Collected
                                </Button>
                            </Col>
                            <Col className="d-flex justify-content-center">
                                <Button disabled={loading} className="btn btn-custome-primary" onClick={handleEditEvent}>
                                    Edit
                                </Button>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <Button disabled={loading} className="btn btn-custome-primary" onClick={handleDeleteEvent}>
                                    Delete
                                </Button>
                            </Col>
                        </Row>
                    }
                </Card.Body>
            </Card>
        </Popup>
      </Row>
      <Row>
        <Popup open={openCreateEvent} onClose={closeCreateEventModal} >
            <Card>
                <Card.Body>
                    <Row>
                        <Col className="d-flex justify-content-end">
                            <Button className="btn-close btn-outline-dark" onClick={closeCreateEventModal} aria-label="Close" />
                        </Col>
                    </Row>
                    <Card.Title>Create Event</Card.Title>
                    <Form onSubmit={handleCreateEventSubmit}>
                        <Form.Group className="mb-3" id="name">
                            <Form.Label>Site</Form.Label>
                            <Form.Control as="select" defaultValue="Chose..." ref={newTitleRef} required>
                                {sites.map((site) => <option value={site.name}>{site.name}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3" id="role">
                            <Form.Label>Date</Form.Label>
                            <div>
                                <DatePicker 
                                    onChange={setDatePick}
                                    value={datePick}
                                    required={true}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Note</Form.Label>
                            <Form.Control ref={noteRef} as="textarea" rows={2} id="note" />
                        </Form.Group>
                        <Button disabled={loading} className="btn-custome-primary w-100 mt-2" type="submit">Create</Button>
                    </Form>
                </Card.Body>
            </Card>
        </Popup>
      </Row>
    </Container>
    </>
    )
}

export default function SamplingCalendar() {
    return (
        <>
            <MyCalendar />
        </>
    )
}
