import React, { useRef, useState, useEffect } from 'react'
import {Form, Button, Card, Alert, Container, Row, Col } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'
import app from '../firebase'
import DataTable from 'react-data-table-component';
import { getAllDocuments, addDocument } from '../contexts/DbContext';
import {mainCategories} from '../Config/constant';

const columns = [
    {
        name: "File Name",
        selector: row => row.name,
        sortable: true,
		reorder: true,
        wrap: true
    },
    {
        name: 'Sample Category',
        selector: row => row.category,
        sortable: true,
		reorder: true,
        wrap: true
    },
    
];

 const DocumentManagement = () =>  {
    const emailRef = useRef();
    const pointRef = useRef();
    const typeRef = useRef();
    const clientRef = useRef();
    const fileRef = useRef();
    const { singUp, currentUser, isAdmin } = useAuth();
    const [error, setError ] = useState("")
    const [documents, setDocuments ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [file, setFile ] = useState(null)
    const history = useHistory();

    const handleFileChange = async(e) => {
        setFile(e.target.files[0]);
    }

    const handleSubmit = async(e) => {
        e.preventDefault();

        try {
            setError("");
            setLoading(true)
            const document = {
                file: file,
                category: typeRef.current.value,
            }

            console.log(file)
            if(file.size > 4096000){
                throw new Error('The uploaded file exceeds max limit of 50kb')
            }

            const result = await addDocument(document);

        } catch (error) {
            console.log(error.message)
            setError(error.message)
        }
        setLoading(false)
        e.target.reset()
        
    }

    useEffect( async() => {
        const allDcouments = await getAllDocuments();
        setDocuments(allDcouments.map(a => ({
            name: a.name,
            category: mainCategories[a.category].description,   
        })))
    }, [loading])



    if(isAdmin()){
        return (
            <>
            <Container fluid className="mt-3">
            {error && <Alert variant="danger">{error}</Alert> }
                <Row>
                    <Col xs="12" ms="12" md="4" lg="4" className="mb-2">
                        <Card className="">
                            <Card.Body>
                                <Card.Title className="text-center mb-4">Add Document</Card.Title>
                                {error && <Alert variant="danger">{error}</Alert> }
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" id="file">
                                        <Form.Control style={{width:"100%"}} type="file" ref={fileRef} onChange={handleFileChange}  required />
                                    </Form.Group>
                                    <Form.Group className="mb-3" id="role">
                                        <Form.Label>Category</Form.Label>
                                        <Form.Control as="select" defaultValue="Chose..." ref={typeRef} required>
                                            <option value="">Choose..</option>
                                            <option value="1">Drinking Water</option>
                                            <option value="2">Surface Water</option>
                                            <option value="3">Ground Water</option>
                                            <option value="4">Trade Waste Effluent</option>
                                            <option value="5">Cooling Towers</option>
                                            <option value="6">Swimming Pools</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Button disabled={loading} className="btn-custome-primary w-100 mt-2" type="submit">Submit</Button>
                                </Form>
                            </Card.Body>
                        </Card>  
                    </Col>
                    <Col>
                        <Card className="">
                            <Card.Body>
                                <Card.Title className="text-center mb-4">Documents</Card.Title>
                                <DataTable
                                    columns={columns}
                                    data={documents}
                                    progressPending={loading}
                                    pagination
                                    responsive
                                />
                                
                            </Card.Body>
                        </Card>  
                    </Col>
                </Row>
            </Container>
            </>
        )
    } else {
        return (<> <h1>You don't have access</h1></>)
    }

    
}

export default DocumentManagement;
