import React, {useState, useEffect}  from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAuth } from '../contexts/AuthContext'
import { useHistory } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const { singUp, currentUser, isAdmin } = useAuth();
  const [userIsAdmin, setUserIsAdmin] = useState(false)
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSamplingGuide = () => {
    history.push('/sampling')
  }

  const handleDocument = () => {
    history.push('/document-management')
  }

  const handleUser = () => {
    history.push('/user-management')
  }

  const handleSite = () => {
    history.push('/site-management')
  }


  const handleSamplingCalendar = () => {
    history.push('/sampling-calendar')
  }

  useEffect(async () => {
    history.push('/sampling')
    setUserIsAdmin(await isAdmin())
  }, [])

  if(userIsAdmin){
    return (
        <>
        <Box sx={{ width: 'auto' }} position="static">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} 
            aria-label="basic tabs example" variant="scrollable"
            scrollButtons="auto">
              <Tab label="Sampling Guide"  onClick={handleSamplingGuide} />
              <Tab label="Sampling Calendar"  onClick={handleSamplingCalendar} />
              <Tab label="Document Management"  onClick={handleDocument} />
              <Tab label="User Mangement"  onClick={handleUser} />
              <Tab label="Site Mangement"  onClick={handleSite} />
            </Tabs>
          </Box>
        </Box>
        </>
      );
  } else{
      return(
    <Box sx={{ width: 'auto' }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} 
      aria-label="basic tabs example" variant="scrollable"
      scrollButtons="auto">
        <Tab label="Sampling Guide"  onClick={handleSamplingGuide} />
        <Tab label="Sampling Calendar"  onClick={handleSamplingCalendar} />
      </Tabs>
    </Box>
  </Box>)
  }

  
}
