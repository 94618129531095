import React, { useContext, useEffect, useState } from 'react'
import app, { auth } from '../firebase'

const Authcontext = React.createContext();

export function useAuth() {
    return useContext(Authcontext);
}

export function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true)

    const singUp = async(user) => {
        const addUser =  app.functions().httpsCallable('addUser');
        const callFunciton = await addUser(user)
        console.log(callFunciton);
    }

    const isAdmin = async() => {
        const token = await auth.currentUser?.getIdTokenResult();
        if(token.claims.admin){
            return true;
        }
        return false;
    }

    function logIn(email, password){
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logout(){
        return auth.signOut();
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email);
    }

    function refreshToken(){
        if(auth.currentUser){
            auth.currentUser.getIdToken(true)
        }
    }

    auth.onAuthStateChanged(user => {
       setCurrentUser(user);
    })

    useEffect(() => {
       const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            refreshToken()
            setLoading(false)
        })

        return unsubscribe;
    }, [])

    const value = {
        currentUser,
        singUp,
        logIn,
        logout,
        resetPassword,
        isAdmin,
    }

    return (
        <Authcontext.Provider value={value}>
            {!loading && children}
        </Authcontext.Provider>
    )
}
