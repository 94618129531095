import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useAuth } from '../contexts/AuthContext'
import { useHistory } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoContainer from './LogoContainer';
import BasicTabs from './TabPanel';
import LogoutIcon from '@mui/icons-material/Logout';


export default function Header() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [error, setError ] = useState("")
    const { currentUser, logout, isAdmin } = useAuth();
    const history = useHistory();
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleClose = () => {
        console.log(currentUser)
        setAnchorEl(null);
    };

    const handleGoSampling = () => {
        history.push('/sampling')
    }

    const handleGoProfile = () => {
        history.push('/profile')
    }
    const handleLogout = async() => {
        setError('');

        try {
            await logout();
            history.push('/login')
        } catch (error) {
            setError(error.message)
        }
    }

    if(currentUser){
      return (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={{backgroundColor:"white", color: "#1f648c"}}>
            <Toolbar>
            <Button className="d-none d-xs-none d-sm-none d-md-block" onClick={handleLogout} 
             style={{color:"#1f648c"}} >Logout</Button>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleLogout}
                sx={{ mr: 0 }}
              >
                <LogoutIcon  className="d-block d-xs-block d-sm-block  d-md-none"/>
                
              </IconButton>
              <Typography onClick={handleGoSampling} className="d-flex
            justify-content-center" component="div" sx={{ flexGrow: 1 }}>
                <div className="mt-1 mb-2 mb-1col-12 col-sm-10 col-md-8 col-lg-4 col-xl-4" 
                style={{maxWidth:"300px"}}><LogoContainer/></div>
              </Typography>
              <Typography className="d-none d-xs-none d-sm-none d-md-block">{currentUser.displayName}</Typography>
              {currentUser && (
                <div>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={handleGoProfile}
                  >
                    <AccountCircle  />
                  </IconButton >
                </div>
              )}
            </Toolbar>
          </AppBar>
          <BasicTabs/>
        </Box>
      );
    }else{
      return (<> </>)
    }
  
}