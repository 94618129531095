import React, {useState, useEffect}from 'react'
import { Card, Container, Row, ListGroup } from 'react-bootstrap'
import { getDocumentsByCategory } from '../contexts/DbContext';
import { mainCategories } from '../Config/constant';
import { Gavel } from '@mui/icons-material';

export default function SurfaceWater() {
    const [documents, setDocuments] = useState([]);
    useEffect(async () => {
        setDocuments(await getDocumentsByCategory(mainCategories[5].id));
    }, [])
    return (
        <>
            <Container fluid className="mt-3">
                <Row>
                    <Card style={{backgroundColor:"#1f648c"}}>
                        <Card.Body >
                            <Card.Title className="text-center text-white">Colling Towers</Card.Title >
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body >
                            <Card.Text>
                            <Gavel/> Work in progress
                            </Card.Text >

                        </Card.Body>
                        
                        <Card.Body className="list-group-flush">
                            <ListGroup.Item evenkey="0"><b>Documents</b></ListGroup.Item>
                            {documents.map(((document) => (
                                 <>
                                    <ListGroup.Item eventkey={document.category}>
                                        <Card.Link href={document.url} target="_blank" 
                                    className="">{document.name}</Card.Link>
                                    </ListGroup.Item>
                                </>
                            )))}
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </>
    )
}
