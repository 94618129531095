import React, {useState, useEffect}from 'react'
import { Card, Container, Row, ListGroup } from 'react-bootstrap'
import { getDocumentsByCategory } from '../contexts/DbContext';
import { mainCategories } from '../Config/constant';

export default function DrinkingWater() {
    const [documents, setDocuments] = useState([]);
    useEffect(async () => {
        setDocuments(await getDocumentsByCategory(mainCategories[1].id));
    }, [])
    return (
        <>
            <Container fluid className="mt-3">
                <Row>
                    <Card style={{backgroundColor:"#1f648c"}}>
                        <Card.Body >
                            <Card.Title className="text-center text-white">Drinking Water</Card.Title >
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body >
                            <Card.Text>
                                <p>
                                The city is divided into zones which must be sampled on rotation according to the
                                DWO_PNCC sampling calendar for microbial and chemical compliance. Six city zones have
                                been created by City Networks to identify water from the several sources supplying the city,
                                and are not recognised by the MOH. An additional sample must be taken daily from the “Lab
                                Tap” for chemical analysis.
                                </p>
                                <p>
                                Samples are collected from designated, locked sampling boxes within each zone 
                                there are one fixed point except for zone 5, which has two
                                </p>
                                <p>
                                A “Drinking Water Sampling Points” list of the locations of sampling boxes, 
                                fixed and back-up sampling points and naming rules are at the end of this procedure. 
                                </p>
                            </Card.Text >
                            <Card.Text>
                            <Card.Subtitle className="mb-2 fw-bold">Equipment and consumables</Card.Subtitle>
                                Palmerston North map showing locations of sampling boxes and aerial photos of box locations.
                            </Card.Text >
                            <Card.Text>
                                TODO
                            </Card.Text>
                        </Card.Body>
                        
                        <Card.Body className="list-group-flush">
                            <ListGroup.Item evenkey="0"><b>Documents</b></ListGroup.Item>
                            {documents.map(((document) => (
                                 <>
                                    <ListGroup.Item eventkey={document.category}>
                                        <Card.Link href={document.url} target="_blank" 
                                    className="">{document.name}</Card.Link>
                                    </ListGroup.Item>
                                </>
                            )))}
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </>
    )
}
