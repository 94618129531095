import React, { useRef, useState } from 'react'
import {Form, Button, Card, Alert } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import { getUserById } from '../contexts/DbContext'
import LogoContainer from './LogoContainer'

 const Login = () =>  {
    const emailRef = useRef();
    const passwordRef = useRef();
    const { logIn } = useAuth();
    const [error, setError ] = useState("")
    const [ loading, setLoading ] = useState(false);
    const history = useHistory();

    const handleSubmit = async(e) => {
        e.preventDefault();

        try {
            setError("");
            setLoading(true)
            const details = await logIn(emailRef.current.value, passwordRef.current.value)
            console.log(details.user);
            history.push("/sampling")
        } catch (error) {
            setError(error.message.replace('Firebase: ',''))
        }
        setLoading(false)
        
    }

    return (
        <>
          <Card style={{maxWidth: "400px"}} className=" mt-4 col-12 col-sm-10 col-md-8 col-lg-4 col-xl-4 mx-auto">
            <Card.Body>
                <div className="d-block mx-auto mb-5"><LogoContainer /></div>
                {error && <Alert variant="danger">{error}</Alert> }
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" id="email">
                        <Form.Label>User Email</Form.Label>
                        <Form.Control type="email" ref={emailRef} required readOnly={loading}/>
                    </Form.Group>
                    <Form.Group className="mb-3" id="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" ref={passwordRef} required readOnly={loading}/>
                    </Form.Group>
                    <Button disabled={loading} className="btn-custome-primary w-100 mt-2" type="submit">Log In</Button>
                </Form>
                <div className="w-50 text-center mt-3 mx-auto">
                    <Link to="/forgot-password">Forgot Password?</Link>
                </div>
            </Card.Body>
          </Card>  
        </>
    )
}

export default Login;