import React from "react";
import UserManagement from "./UserManagement";
import SiteManagement from "./SiteManagement";
import DocumentManagement from "./DocumentManagement";
import { Container } from "react-bootstrap";
import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Login from "./Login";
import PrivateRoute from "./PrivateRoute";
import ForgotPassword from "./ForgotPassword";
import Profile from "./Profile";
import Header from "./Header"
import Sampling from "./Sampling";
import DrinkingWater from "./DrinkingWater";
import SurfaceWater from "./SurfaceWater";
import GroundWater from "./GroundWater";
import TradeWasteEffluent from "./TradeWasteEffluent";
import CoolingTowers from "./CoolingTowers";
import SwimmingPools from "./SwimmingPools";
import SamplingCalendar from "./SamplingCalendar";
import "../customStyle/style.css"

function App() {
  return (
    
      <Container 
        className="d-flex mt-2
        justify-content-center"
        style={{ minHeight: "100vh", maxWidth: "100%" }}
        >
        <div className="w-100"
          >
            <Router>
              <AuthProvider>
                <Header/>
                <Switch>
                  <PrivateRoute exact path="/" component={Sampling} />
                  <PrivateRoute exact path="/profile" component={Profile} />
                  <PrivateRoute path="/user-management" component={UserManagement} />
                  <PrivateRoute path="/site-management" component={SiteManagement} />
                  <PrivateRoute path="/document-management" component={DocumentManagement} />
                  <PrivateRoute path="/sampling" component={Sampling} />
                  <PrivateRoute path="/drinking-water" component={DrinkingWater} />
                  <PrivateRoute path="/surface-water" component={SurfaceWater} />
                  <PrivateRoute path="/ground-water" component={GroundWater} />
                  <PrivateRoute path="/trade-waste-effluent" component={TradeWasteEffluent} />
                  <PrivateRoute path="/cooling-towers" component={CoolingTowers} />
                  <PrivateRoute path="/swimming-pools" component={SwimmingPools} />
                  <PrivateRoute path="/sampling-calendar" component={SamplingCalendar} />
                  <Route path="/login" component={Login} />
                  <Route path="/forgot-password" component={ForgotPassword} />
                </Switch>
              </AuthProvider>
            </Router>
        </div>
      </Container>
  
    
    )
}

export default App;
