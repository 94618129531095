import React, { useRef, useState, useEffect } from 'react'
import {Form, Button, Card, Alert, Container, Row, Col } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'
import app from '../firebase'
import { addSite, getAllSites, getAllUsers } from '../contexts/DbContext'
import DataTable from 'react-data-table-component';

const columns = [
    {
        name: 'Name',
        selector: row => row.name,
    },
    {
        name: 'Latitude',
        selector: row => row.latitude,
    },
    {
        name: 'Longitude',
        selector: row => row.longitude,
    },
    {
        name: 'Description',
        selector: row => row.description,
    },
];

 const SiteManagement = () =>  {
    const nameRef = useRef();
    const latitudeRef = useRef();
    const longitudeRef = useRef();
    const descriptionRef = useRef();
    const [sites, setSites] = useState([])
    const roleRef = useRef();
    const { singUp, currentUser, isAdmin } = useAuth();
    const [error, setError ] = useState("")
    const [users, setUesrs ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const history = useHistory();

    const handleSubmit = async(e) => {
        e.preventDefault();

        try {
            setError("");
            setLoading(true)
            const site = {
                name: nameRef.current.value,
                longitude: latitudeRef.current.value,
                latitudeRef: longitudeRef.current.value,
                description: descriptionRef.current.value
            }
            const result = await addSite(site)
            // Add error check base on userDate 

        } catch (error) {
            console.log(error.message)
            setError(error.message)
        }
        setLoading(false)
        e.target.reset()
    }

    useEffect( async() => {
        setSites(await getAllSites())
    }, [loading])

    if(isAdmin()){
        return (
            <>
            <Container fluid className="mt-3">
            {error && <Alert variant="danger">{error}</Alert> }
                <Row>
                    <Col xs="12" ms="12" md="4" lg="4" className="mb-2">
                        <Card className="">
                            <Card.Body>
                            <Card.Title className="text-center mb-4">Add Site</Card.Title>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" id="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" ref={nameRef} required />
                                    </Form.Group>
                                    <Form.Group className="mb-3" id="email">
                                        <Form.Label>Latitude</Form.Label>
                                        <Form.Control type="text" ref={latitudeRef}  />
                                    </Form.Group>
                                    <Form.Group className="mb-3" id="email">
                                        <Form.Label>Longitude</Form.Label>
                                        <Form.Control type="text" ref={longitudeRef}  />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control ref={descriptionRef} as="textarea" rows={2} id="description" />
                                    </Form.Group>
                                    <Button disabled={loading} className="btn-custome-primary w-100 mt-2" type="submit">Submit</Button>
                                </Form>
                            </Card.Body>
                        </Card>  
                    </Col>
                    <Col>
                        <Card className="">
                            <Card.Body>
                                <h4 className="text-center mb-4">Sites</h4>
                                <DataTable
                                    columns={columns}
                                    data={sites}
                                    progressPending={loading}
                                    pagination
                                />
                                
                            </Card.Body>
                        </Card>  
                    </Col>
                </Row>
            </Container>
            </>
        )
    } else {
        return (<> <h1>You don't have access</h1></>)
    }

    
}

export default SiteManagement;
