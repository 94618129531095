import React from 'react'

export default function LogoContainer() {
    return (
        <>
            <div className="d-flex justify-content-center" >
                    <img src={process.env.PUBLIC_URL + "/cel_logo_left1.png"} style={{maxHeight:"20%", maxWidth:"20%"}}  alt=""/>
                    <img src={process.env.PUBLIC_URL + "/cel_logo_left2.png"} style={{maxHeight:"20%", maxWidth:"20%"}} alt=""/>
                    <img src={process.env.PUBLIC_URL + "/cel_logo_middle.png"} style={{maxHeight:"20%", maxWidth:"20%"}} alt=""/>
                    <img src={process.env.PUBLIC_URL + "/cel_logo_right1.png"}  style={{maxHeight:"20%", maxWidth:"20%"}} alt=""/>
                    <img src={process.env.PUBLIC_URL + "/cel_logo_right2.png"}  style={{maxHeight:"20%", maxWidth:"20%"}} alt=""/>
                </div> 
        </>
    )
}
