import React, {useState, useEffect} from 'react'
import { getAllDocuments } from '../contexts/DbContext'
import { useHistory } from 'react-router-dom';
import {Form, Button, Card, Alert, Container, Row, Col, ListGroup} from 'react-bootstrap'

export default function Sampling() {
    const [documents, setDocuments] = useState([])
    const history = useHistory();
    const handleDrinkingWater = () => {
        history.push("/drinking-water");
    }
    const handleSurfaceWater = () => {
        history.push("/surface-water");
    }

    const handleGroundWater = () => {
        history.push("/ground-water");
    }

    const handleTradeWasteEffluent = () => {
        history.push("/trade-waste-effluent");
    }

    const handleCoolingTowers = () => {
        history.push("/cooling-towers");
    }

    const handleSwimmingPools = () => {
        history.push("/swimming-pools");
    }

    useEffect(async() => {
        setDocuments(await getAllDocuments())
    }, [])
    return (
        <Container fluid className="mt-3">
            <Row>
                 <Col xs="12" ms="12" md="4" lg="4" xl="4" className="mb-2">
                    <Card className="card-custom" onClick={handleDrinkingWater} 
                        style={{transition: "border-color 1s, box-shadow 0.5s"}}>
                        <Card.Body style={{backgroundColor:"#1f648c"}}>
                            <h6 className="text-center text-white">Drinking Water</h6>
                            <a href="#" class="stretched-link"></a>
                        </Card.Body>
                    </Card>  
                </Col>
                <Col xs="12" ms="12" md="4" lg="4" xl="4"  className="mb-2 primary">
                    <Card className="card-custom" onClick={handleSurfaceWater}>
                        <Card.Body style={{backgroundColor:"#3275a8"}}>
                            <h6 className="text-center text-white ">Surface Water</h6>
                            <a href="#" class="stretched-link"></a>
                        </Card.Body>
                    </Card>  
                </Col>
                <Col xs="12" ms="12" md="4" lg="4" xl="4"  className="mb-2 primary">
                    <Card className="card-custom" onClick={handleGroundWater}>
                        <Card.Body style={{backgroundColor:"#3289a8"}}>
                            <h6 className="text-center text-white">Ground Water</h6>
                        </Card.Body>
                    </Card>  
                </Col>
            </Row>
            <Row>
                <Col xs="12" ms="12" md="4" lg="4" xl="4" className="mb-2">
                    <Card className="card-custom" onClick={handleTradeWasteEffluent}>
                        <Card.Body style={{backgroundColor:"#32a8a2"}}>
                            <h6 className="text-center text-white">Trade Waste Effluent</h6>
                        </Card.Body>
                    </Card>  
                </Col>
                <Col xs="12" ms="12" md="4" lg="4" xl="4"className="mb-2">
                    <Card className="card-custom" onClick={handleCoolingTowers}>
                        <Card.Body style={{backgroundColor:"#32a86f"}}>
                            <h6 className="text-center text-white">Cooling Towers</h6>
                        </Card.Body>
                    </Card>  
                </Col>
                <Col xs="12" ms="12" md="4" lg="4" xl="4"className="mb-2">
                    <Card className="card-custom" onClick={handleSwimmingPools}>
                        <Card.Body style={{backgroundColor:"#86c293"}}>
                            <h6 className="text-center text-white">Swimming Pools</h6>
                        </Card.Body>
                    </Card>  
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <Card.Body >
                            <Card.Title className="text-center ">Sampling</Card.Title >
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body >
                            <Card.Text>
                            <Card.Subtitle className="mb-2 fw-bold">Legalities and responsibilities</Card.Subtitle>
                                <p>
                                Samplers must ensure that they are familiar with all the requirements and potential legal
                                consequences of sampling activities that they perform. In many cases a client may be fined
                                for not meeting their sampling or testing commitments. Where the sampling has been
                                subcontracted to CEL, these responsibilities and liabilities may be transferred to us. CEL has
                                professional indemnity insurance that may provide cover. In certain cases a client may have
                                cause to prove negligence and may proceed with personal liability of a sampler. A
                                consequence of not complying with client requirements is that CEL may lose a contract to that
                                client.
                                </p>
                               <p>
                                In all cases a sampler has the responsibility to ensure that they are familiar with current
                                procedures. These will be documented in the sampling manual that must be taken with when
                                sampling is done.
                               </p>
                               <p>
                               It is the responsibility of the KTP in charge of sampling to ensure that documentation relating
                               to sampling is up to date.
                               </p>
                            </Card.Text >
                            <Card.Text>
                                <Card.Subtitle className="mb-2 fw-bold">General Rules</Card.Subtitle>
                                <p>
                                All samples must be taken in such a way that they are representative of the purpose of the
                                sample. The sample purpose could range from consents (regulatory), monitoring, charges
                                according to analyte levels by the client or process design or investigation. Sample frequency,
                                location and type are set by the client. Sample and sub sample size, volume, container,
                                preservation, transport and delivery time to the laboratory are determined by the laboratory in
                                collaboration with the client according to the purpose and tests required.
                                </p>
                                <p>
                                A sampler must consider site and weather conditions before proceeding with any request,
                                whether it has been scheduled or not. If there is any doubt that the sample purpose may be
                                compromised the client should be contacted. In all cases the safety of the sampler must be
                                the first consideration.
                                </p>
                                <p>
                                Details must be recorded on the appropriate sample container/s, subsheet, logbook or site
                                record. Wet or chilled sample containers must be wiped dry before labelling with permanent
                                markers. Logbooks and worksheets may need to be transported in plastic sleeves for
                                protection.
                                </p>
                            </Card.Text >
                            <Card.Text>
                            <Card.Subtitle className="mb-2 fw-bold">The following must be recorded for all samples taken.</Card.Subtitle>
                                <ul>
                                    <li>Vehicle log – time and distance</li>
                                    <li>Sample name, date, time and type</li>
                                    <li>Sampler name or initial</li>
                                    <li>Test/s requested</li>
                                    <li>Complete detail of client that has requested the samples.</li>
                                    <li>Sample date and time delivery/receipt to the laboratory</li>
                                </ul>
                            </Card.Text>
                            <Card.Text>
                            <Card.Subtitle className="mb-2 fw-bold">The following details are sample/site dependant 
                                    and will be specified in the sample procedure</Card.Subtitle>
                                <ul>
                                    <li>Onsite equipment calibration readings including units</li>
                                    <li>Onsite readings including units</li>
                                    <li>Weather conditions</li>
                                    <li>Site condition</li>
                                    <li>If site conditions are unusual photos should be taken</li>
                                    <li>Where possible GPS co-ordinates must be taken</li>
                                </ul>
                            </Card.Text>
                            <Card.Text>
                            <Card.Subtitle className="mb-2 fw-bold">General Safety Precautions</Card.Subtitle>

                                <p>
                                Samplers are not permitted to enter manholes or other confined spaces unless they have 
                                been on ‘confined space training’. Storm water culverts longer than 20 m, or 
                                storm water drains longer than 2 m are considered equivalent to manholes.
                                </p>
                                <p>
                                In these situations, samples should be obtained through the use of 
                                auto-sampling pumps and/or the “sampling arm”. 
                                </p>
                                <p>
                                All trade waste and sewage samples should be treated as being potentially 
                                hazardous to health and safety. Basic safety precautions such as gloves and 
                                overalls/coats should be worn unless the waste is known to be safe.  
                                </p>
                                <p>
                                Each individual that takes on sampling responsibilities must 
                                think about safety at all times. Whereas each sampling site has its own hazards and safety precautions, the following general precautions also apply.
                                </p>
                                <ol className="list-group list-group-numbered">
                                    <li className="list-group-item">Samplers must wear high visibility clothing when in the field. </li>
                                    <li className="list-group-item">Samplers must have an identity document on them at all times.</li>
                                    <li className="list-group-item">A First Aid kit must be available in the sampling vehicle at all times.</li>
                                    <li className="list-group-item">TODO.</li>
                                </ol>


                            </Card.Text>
                            <Card.Text>
                            <Card.Subtitle className="mb-2 fw-bold">Site Specific Safety Precautions</Card.Subtitle>
                                Each sampling site has specific hazards associated with it. 
                                These hazards have been identified and listed on a “Hazard Identification Form” 
                                for each site that CEL samples. All of these forms are kept in the “Sampling Hazards” 
                                binder, and are updated whenever a new hazard is identified. Before undertaking sampling 
                                responsibilities at a particular site, the sampler must read and sign the “Hazard Identification 
                                Form” associated with that site.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>
            </Row>
    </Container>
    )
}
