
const mainCategories = {
    1: {
        description: "Drinking Water",
        id: 1,
    },
    2: {
        description: "Surface Water",
        id: 2,
    },
    3: {
        description: "Ground Water",
        id: 3
    },
    4: {
        description: "Trade Waste Effluent",
        id: 4
    },
    5: {
        description: "Cooling Towers",
        id: 5
    },
    6: {
        description: "Swimming Pools",
        id: 6
    },
}

export {
    mainCategories,
}
